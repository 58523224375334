import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Arrow from "../components/Arrow"
import Footer from "../components/sliderFooter"
import Logo from "./logo"

import { StaticQuery, graphql } from "gatsby"
import PageLinkFade from "../components/PageTransitionFade"

export default class Menu extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query MenuQuery {
                        events: allDatoCmsEvent {
                            nodes {
                                slug
                                title
                                id
                                hidePage
                                heroImage {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                `}
                render={({ events }) => {
                    const actualEventsCount = events.nodes.reduce((total, current) => (!current.hidePage ? (total += 1) : (total += 0)), 0)
                    if (this.props.mobile === false) {
                        return (
                            <div className='fixed back-black t-0   h-100-vh o-p-0  t w-100-vw z-4 back-black' id='menu'>
                                <div id='menu-content' className=' flex flex-c h-100-p w-100-p o-p-0'>
                                    <div className='p-b-95 flex-grow-1  flex flex-r  al-end '>
                                        <div className=' h-100-p w-100-p flex-r flex al-end  '>
                                            <div className='w-100-p p-r-40 p-l-40'>
                                                <div className='flex flex-r'>
                                                    <div className='w-15-p'>
                                                        <Logo className='h-34-5 f-white' />
                                                    </div>
                                                    <div className='w-50-p flex flex-r'>
                                                        <div className='flex flex-c m-r-50'>
                                                            <PageLinkFade to='/'>
                                                                <div className='nav p-b-20 navF grey head' onMouseEnter={this.props.hoverGrey} onMouseLeave={this.props.hoverOutGrey} role='button' tabIndex={0}>
                                                                    Home
                                                                </div>
                                                            </PageLinkFade>
                                                            <button id='disoverMenuButton' className='nav p-b-20 grey c-p navF ' onClick={this.props.displayLinks} onMouseEnter={this.props.hoverGrey} onMouseLeave={this.props.hoverOutGrey}>
                                                                Discover
                                                            </button>
                                                            {actualEventsCount > 0 && (
                                                                <button id='eventMenuButton' className='nav p-b-20 grey c-p navF ' onClick={this.props.displayEventLinks} onMouseEnter={this.props.hoverGrey} onMouseLeave={this.props.hoverOutGrey}>
                                                                    Experiences
                                                                </button>
                                                            )}
                                                            <PageLinkFade to='/memberships'>
                                                                <div className='nav p-b-20 navF grey head' onMouseEnter={this.props.hoverGrey} onMouseLeave={this.props.hoverOutGrey} role='button' tabIndex={0}>
                                                                    Memberships
                                                                </div>
                                                            </PageLinkFade>
                                                            <PageLinkFade to='/events'>
                                                                <div className='nav p-b-20 navF grey head' onMouseEnter={this.props.hoverGrey} onMouseLeave={this.props.hoverOutGrey} role='button' tabIndex={0}>
                                                                    Corporate consultancy
                                                                </div>
                                                            </PageLinkFade>
                                                            <PageLinkFade to='/about'>
                                                                <div className='nav p-b-20 navF grey head' to='/about' onMouseEnter={this.props.hoverGrey} onMouseLeave={this.props.hoverOutGrey} role='button' tabIndex={0}>
                                                                    About
                                                                </div>
                                                            </PageLinkFade>
                                                            <PageLinkFade to='/contact'>
                                                                <div className='nav navF head grey' to='/contact' onMouseEnter={this.props.hoverGrey} onMouseLeave={this.props.hoverOutGrey} role='button' tabIndex={0}>
                                                                    Contact
                                                                </div>
                                                            </PageLinkFade>
                                                        </div>
                                                        <div className='flex flex-c o-p-0' id='page-links'>
                                                            {!this.props.isEventVisible ? (
                                                                <div id='discover-links'>
                                                                    <PageLinkFade to='/thetrip'>
                                                                        <div className='nav p-b-20 grey  grey head' id='theTrip' role='button' tabIndex={0} onMouseEnter={this.props.hoverLinks} onMouseLeave={this.props.hoverOutLinks}>
                                                                            The Trip
                                                                        </div>
                                                                    </PageLinkFade>
                                                                    <PageLinkFade to='/thelife'>
                                                                        <div className='nav p-b-20 grey grey head' id='theLife' role='button' tabIndex={0} onMouseEnter={this.props.hoverLinks} onMouseLeave={this.props.hoverOutLinks}>
                                                                            The Life
                                                                        </div>
                                                                    </PageLinkFade>
                                                                    <PageLinkFade to='/thetaste'>
                                                                        <div className='nav p-b-20 grey head' id='theTaste' role='button' tabIndex={0} onMouseEnter={this.props.hoverLinks} onMouseLeave={this.props.hoverOutLinks}>
                                                                            The Taste
                                                                        </div>
                                                                    </PageLinkFade>

                                                                    <PageLinkFade to='/theculture'>
                                                                        <div className='nav p-b-20 grey head' id='theCulture' role='button' tabIndex={0} onMouseEnter={this.props.hoverLinks} onMouseLeave={this.props.hoverOutLinks}>
                                                                            The Culture
                                                                        </div>
                                                                    </PageLinkFade>
                                                                    <PageLinkFade to='/thegame'>
                                                                        <div className='nav p-b-20 grey head' id='theGame' role='button' tabIndex={0} onMouseEnter={this.props.hoverLinks} onMouseLeave={this.props.hoverOutLinks}>
                                                                            The Game
                                                                        </div>
                                                                    </PageLinkFade>
                                                                </div>
                                                            ) : (
                                                                <div id='event-links'>
                                                                    {events.nodes.map(event => {
                                                                        if (event.hidePage) return null
                                                                        return (
                                                                            <PageLinkFade key={event.id} to={`/event/${event.slug}`}>
                                                                                <div className='nav p-b-20 grey head' id={event.id} role='button' tabIndex={0} onMouseEnter={this.props.hoverEventLinks} onMouseLeave={this.props.hoverOutEventLinks}>
                                                                                    {event.title}
                                                                                </div>
                                                                            </PageLinkFade>
                                                                        )
                                                                    })}
                                                                    <PageLinkFade to='/experiences'>
                                                                        <div className='nav grey head p-b-20'>View All</div>
                                                                    </PageLinkFade>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='flex-grow-1 flex flex-c'>
                                                        <div className='m-w-305'>
                                                            <p className='body-text c-BCBCBC'>TWO4SEVEN delivers connections, events, elite travel, matchless experiences, and exceptional tailored services to leading corporate organisations and individuals – facilitating and managing all business and personal needs</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex-grow-1 relative '>
                                        <div className=' o-0  absolute w-100-p h-100-p image-container fade-out-1-alt ' id='theTripMenu'>
                                            <StaticImage alt='' src='../images/TheTrip.jpeg' className='bg-image  ' placeholder='dominantColor' objectPosition='50% 50%' objectFit='cover' />
                                        </div>
                                        <div className=' o-0  absolute w-100-p h-100-p image-container fade-out-1-alt ' id='theLifeMenu'>
                                            <StaticImage alt='' src='../images/TheLife.jpeg' className='bg-image  ' placeholder='dominantColor' objectPosition='50% 50%' objectFit='cover' />
                                        </div>
                                        <div className=' o-0  absolute w-100-p h-100-p image-container fade-out-1-alt ' id='theTasteMenu'>
                                            <StaticImage alt='' src='../images/TheTaste.jpeg' className='bg-image  ' placeholder='dominantColor' objectPosition='50% 50%' objectFit='cover' />
                                        </div>
                                        <div className=' o-0 absolute w-100-p h-100-p image-container fade-out-1-alt ' id='theCultureMenu'>
                                            <StaticImage alt='' src='../images/TheCulture.jpeg' className='bg-image  ' placeholder='dominantColor' objectPosition='50% 50%' objectFit='cover' />
                                        </div>
                                        <div className=' o-0 absolute w-100-p h-100-p image-container fade-out-1-alt ' id='theGameMenu'>
                                            <StaticImage alt='' src='../images/TheGame.jpeg' className='bg-image  ' placeholder='dominantColor' objectPosition='50% 50%' objectFit='cover' />
                                        </div>
                                        {events.nodes.map(event => (
                                            <div className=' o-0 absolute w-100-p h-100-p image-container fade-out-1-alt ' id={`${event.id}-image`} key={event.id}>
                                                <GatsbyImage alt='' image={event.heroImage?.gatsbyImageData} className='bg-image  ' placeholder='dominantColor' objectPosition='50% 50%' objectFit='cover' />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className='fixed back-black t-0 m-w-100vw  h-100-vh o-p-0  t w-100-vw z-4 back-black  p-t-80 flex flex-c ' id='menu'>
                                <div id='menu-content' className='flex flex-c  h-100-p w-100-p o-p-0  flex-grow-1 p-t-60'>
                                    <div className='p-r-30 p-l-30 flex flex-c flex-grow-1'>
                                        <div className='flex '>
                                            <Logo className='h-40 f-white' />
                                        </div>
                                        <div className={this.props.showMenu === false ? "flex flex-grow-1 flex-j-end  flex-c p-b-120 fade-1-v-e absolute b-0 d-1" : "flex flex-grow-1 flex-j-end  flex-c p-b-120  fade-1-v-1 o-p-0 absolute b-0"}>
                                            <div>
                                                <PageLinkFade to='/'>
                                                    <div className='nav p-b-5'>Home</div>
                                                </PageLinkFade>
                                            </div>
                                            <div>
                                                <button className='nav p-b-5' onClick={this.props.displayLinks}>
                                                    Discover
                                                </button>
                                            </div>
                                            {actualEventsCount > 0 && (
                                                <div>
                                                    <button className='nav p-b-5' onClick={this.props.displayEventLinks}>
                                                        Experiences
                                                    </button>
                                                </div>
                                            )}
                                            <div>
                                                <PageLinkFade to='/memberships'>
                                                    <div className='nav p-b-5'>Memberships</div>
                                                </PageLinkFade>
                                            </div>
                                            <div>
                                                <PageLinkFade to='/events'>
                                                    <div className='nav p-b-5'>Corporate consultancy</div>
                                                </PageLinkFade>
                                            </div>
                                            <div>
                                                <PageLinkFade to='/about'>
                                                    <div className='nav p-b-5'>About</div>
                                                </PageLinkFade>
                                            </div>
                                            <div>
                                                <PageLinkFade to='/contact'>
                                                    <div className='nav p-b-5'>Contact</div>
                                                </PageLinkFade>
                                            </div>
                                            <div>
                                                <PageLinkFade to='/contact'>
                                                    <div className='nav p-b-5'>I'm Interested</div>
                                                </PageLinkFade>
                                            </div>
                                        </div>
                                        <div className={this.props.showMenu === true ? "flex flex-grow-1 flex-j-end absolute flex-c p-b-120-b fade-1-v-e b-0 d-1" : "flex flex-grow-1 flex-j-end  flex-c p-b-120-b   fade-1-v-1 o-p-0 absolute b-0"}>
                                            <button className='nav p-b-5 flex flex-r flex-center-v' onClick={this.props.isEventVisible ? this.props.displayEventLinks : this.props.displayLinks}>
                                                <Arrow className={`f-white absolute r-b ab-l-0 f-i-1-b  t-180  `} />
                                                <div className={`button c-white trans`}>Go Back</div>
                                            </button>
                                            {!this.props.isEventVisible ? (
                                                <div>
                                                    <div>
                                                        <PageLinkFade to='/thetrip'>
                                                            <div className='nav p-b-5'>The Trip</div>
                                                        </PageLinkFade>
                                                    </div>
                                                    <div>
                                                        <PageLinkFade to='/thelife'>
                                                            <div className='nav p-b-5'>The Life</div>
                                                        </PageLinkFade>
                                                    </div>
                                                    <div>
                                                        <PageLinkFade to='/thetaste'>
                                                            <div className='nav p-b-5'>The Taste</div>
                                                        </PageLinkFade>
                                                    </div>
                                                    <div>
                                                        <PageLinkFade to='/theculture'>
                                                            <div className='nav p-b-5'>The Culture</div>
                                                        </PageLinkFade>
                                                    </div>
                                                    <div>
                                                        <PageLinkFade to='/thegame'>
                                                            <div className='nav p-b-5'>The Game</div>
                                                        </PageLinkFade>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div id='event-links'>
                                                    {events.nodes.map(event => {
                                                        if (event.hidePage) return null
                                                        return (
                                                            <PageLinkFade key={event.id} to={`/event/${event.slug}`}>
                                                                <div className='nav p-b-5'>{event.title}</div>
                                                            </PageLinkFade>
                                                        )
                                                    })}
                                                    <PageLinkFade to='/experiences'>
                                                        <div className='nav p-b-5'>View All</div>
                                                    </PageLinkFade>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <Footer classNameText='c-white' className='b-t-1-w' />
                                </div>
                            </div>
                        )
                    }
                }}
            />
        )
    }
}
